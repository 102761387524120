<template>
  <router-link :to="{ name: 'release-detail', params: { id: album.AlbumID } }" v-if="album">
    <template v-if="prefix">{{ prefix }} </template>
    <strong>{{ album.ProductDetails.Attribution }}</strong> - {{ album.ProductDetails.Title }} {{ album.ProductDetails.VersionTitle }}
  </router-link>
</template>

<script>
  export default {
    name: 'ReleaseLink',
    components: {
    },
    props: {
      prefix: String,
      album: [Boolean, Object],
    },
  }
</script>